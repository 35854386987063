<template>
	<div>
		<a-modal :visible="visible" :title="title" :closable="false">
			<template slot="footer">
				<a-button key="back" @click="onCancel"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="handleSubmit"> {{ submitLabel }} </a-button>
			</template>
			<a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">
				<a-form layout="vertical" :form="form" @submit="handleSubmit">
					<div class="row">
						<div class="col-md-12">
							<a-form-item label="Nombre">
								<a-input
									v-decorator="[
										'name',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]"
									autocomplete="off"
								/>
							</a-form-item>
						</div>
						<div class="col-md-12">
							<a-form-item label="Descripción">
								<a-textarea v-decorator="['description']" />
							</a-form-item>
						</div>
					</div>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>
<script>
//
import utilities from '@/services/utilities'

export default {
	name: 'insertOrUpdateexpenseTypes',
	components: {},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: '',
		},
		payload: {
			type: Object,
			default: () => {
				return {}
			},
		},
	},
	computed: {
		spinnerLoaderLabel() {
			return this.$store.state.expenseTypes.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.expenseTypes.spinnerLoader
		},
	},
	data() {
		return {
			form: this.$form.createForm(this),
			submitLabel: '',
		}
	},
	methods: {
		onCancel() {
			this.$emit('closeModal')
			this.form.resetFields()
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (!err) {
					//
					let payload = {
						...values,
					}
					if (utilities.objectValidate(this.payload, 'id', false)) {
						payload.id = this.payload.id
						this.$store
							.dispatch('expenseTypes/UPDATE', payload)
							.then((response) => {
								// console.log('expenseTypes/UPDATE::response-->', response)
								this.onCancel()
							})
							.catch((error) => {
								// console.log('expenseTypes/UPDATE::error-->', error)
							})
					} else {
						this.$store
							.dispatch('expenseTypes/CREATE', payload)
							.then((response) => {
								// console.log('expenseTypes/CREATE::response-->', response)
								this.onCancel()
							})
							.catch((error) => {
								// console.log('expenseTypes/CREATE::error-->', error)
							})
					}
				}
			})
		},
	},
	watch: {
		payload(newValue) {
			setTimeout(() => {
				if (utilities.objectValidate(newValue, 'id', false)) {
					this.form.setFieldsValue({
						name: utilities.objectValidate(newValue, 'name', ''),
						description: utilities.objectValidate(newValue, 'description', ''),
					})
					this.submitLabel = 'Actualizar'
				} else {
					this.submitLabel = 'Guardar'
				}
			}, 100)
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>